module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@5.12.11_babel-eslint@10.1.0_eslint@8.55.0__react-d_8299b6bfee8e5e8f9e576cdf16da1148/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Digital Spiders","start_url":"/","background_color":"#010b24","theme_color":"#010b24","display":"minimal-ui","icon":"src/images/favicon.svg","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"4802dc7e734a409622c0a317dc4a63f1"},
    },{
      plugin: require('../node_modules/.pnpm/@sentry+gatsby@7.85.0_gatsby@5.12.11_babel-eslint@10.1.0_eslint@8.55.0__react-dom@18.2._1e0bc3265166ca37f624ed97f0591eed/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.12.3_patch_hash=dea8c680d3a679da9fea1862aa8cfe832fe91_da07f2b4a71b3af6d593cd0300c4da0e/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W92FZF9J","enableWebVitalsTracking":true,"includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.11_babel-eslint@10.1.0_eslint@8.55.0__react-dom@18.2.0_react@18.2.0__react@_744ae06aac5d496b059e44f277477a2d/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
